<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { updateProjectV2 } from '@/services/api/v2/projects.v2.api';
import {
  createRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';

const namespace = 'dhmeProjectIntakeStore';

export default {
  name: 'DhmeProjectIntakeInfo',
  components: { AntInput },
  data: () => {
    return {
      metadata: null,
      metadataTableId: null,
      isLoading: false,
      rules: {
        numberValidation: (value) =>
          /^[A-Za-z]\.\d{6}$/.test(value) ||
          'Project number heeft een onjuist format. (P.000001',
      },
    };
  },
  computed: {
    ...mapGetters({
      masterProject: 'project',
      intakeProject: 'dhmeProjectIntakeStore/selectedProject',
      isDaiwaLicense: 'isDaiwaLicense',
    }),
  },
  mounted() {
    this.fetchMetaData();
  },
  methods: {
    async fetchMetaData() {
      this.isLoading = true;
      const { metadata } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_DATA',
            project: this.masterProject.id,
            as: 'metadata',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.intakeProject.id,
                  },
                ],
              },
              ...[
                'name',
                'project_number',
                'firm',
                'status',
                'location',
                'ukg_number',
                'consequence_class',
                'quality_assurance_firm',
                'requirement_notification_number',
                'vca_certification',
              ].map((key) => ({ name: key })),
            ],
          },
        ],
      });

      this.metadata = metadata.records[0];
      this.metadataTableId = metadata.id;
      this.isLoading = false;
    },
    async updateMetadata(key, value) {
      this.isLoading = true;
      if (['name', 'project_number'].includes(key)) {
        // update project fields
        let data = {
          name: this.metadata.name,
          number: this.metadata.project_number,
          description: this.metadata.project_number + this.metadata.name,
        };
        if (key === 'project_number') {
          data.number = value;
        } else {
          data[key] = value;
        }
        await updateProjectV2(this.metadata.project_id, data);
      } else if ('firm' === key) {
        try {
          let { masterConfig, projectConfig } = await queryTablesV2({
            tables: [
              {
                name: 'CFFA_FORGE_CLIENT_CONFIG',
                project: this.masterProject.id,
                as: 'masterConfig',
                columns: [
                  {
                    name: 'firm',
                    conditions: [
                      {
                        operator: '=',
                        value: value,
                      },
                    ],
                  },
                  {
                    name: 'config',
                  },
                ],
              },
              {
                name: 'CFFA_FORGE_CLIENT',
                project: this.metadata.project_id,
                as: 'projectConfig',
                columns: [
                  {
                    name: 'client_id',
                  },
                  {
                    name: 'secret',
                  },
                  {
                    name: 'server_region',
                  },
                ],
              },
            ],
          });

          if (masterConfig.records[0]) {
            if (projectConfig.records[0]) {
              await updateRecordV2(
                projectConfig.id,
                projectConfig.records[0].id,
                JSON.parse(masterConfig.records[0].config)
              );
            } else {
              await createRecordV2(
                projectConfig.id,
                JSON.parse(masterConfig.records[0].config)
              );
            }
          }
        } catch (e) {}
      }

      try {
        await updateRecordV2(this.metadataTableId, this.metadata.id, {
          [key]: value,
        });
        this.metadata[key] = value;

        this.$store.commit('dhmeProjectIntakeStore/updateMetadata', {
          key,
          value,
        });
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = this.updateProjectDataRecord;
        reader.readAsText(file);
      }
    },
    async updateProjectDataRecord(event) {
      const csvContent = event.target.result;
      let tmp = this.csvToJson(csvContent);

      this.metadata = await updateRecordV2(
        this.metadataTableId,
        this.metadata.id,
        tmp
      );

      let projectUpdateObj = {};
      if (tmp.project_number || tmp.name) {
        projectUpdateObj.number =
          tmp.project_number ?? this.intakeProject.number;
        projectUpdateObj.name = tmp.project_number ?? this.intakeProject.name;

        await updateProjectV2(this.metadata.project_id, projectUpdateObj);
      }
    },
    csvToJson(csv) {
      // Helper function to determine the delimiter
      const determineDelimiter = (line) => {
        const delimiters = [',', ';', '|'];
        let maxCount = 0;
        let detectedDelimiter = ',';

        delimiters.forEach((delim) => {
          const count = line.split(delim).length - 1;
          if (count > maxCount) {
            maxCount = count;
            detectedDelimiter = delim;
          }
        });

        return detectedDelimiter;
      };

      // Split CSV into lines
      const lines = csv.split('\n');

      // Determine the delimiter using the first line
      const delimiter = determineDelimiter(lines[0]);

      // Split headers and first row using the detected delimiter
      const headers = lines[0].split(delimiter);
      const firstRow = lines[1].split(delimiter);

      const obj = {};

      // Add project_id to the JSON object
      obj['project_id'] = this.intakeProject.id;

      // Populate the rest of the JSON object with CSV data
      headers.forEach((header, index) => {
        let value =
          firstRow[index] !== undefined ? firstRow[index].trim() : null;
        obj[header.trim()] = value === '' ? null : value;
      });

      return obj;
    },
  },
};
</script>

<template>
  <v-card :loading="isLoading" class="ma-5">
    <v-card-title class="d-flex"
      >Algemene project informatie

      <v-spacer />
      <input
        ref="fileInput"
        type="file"
        accept=".csv"
        style="display: none"
        @change="handleFileUpload"
      />
      <v-btn color="primary" @click="$refs.fileInput.click()">Importeren</v-btn>
    </v-card-title>
    <v-card-text>
      <form v-if="metadata">
        <v-row>
          <v-col>
            <ant-input label="Project naam" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="metadata.name"
                  filled
                  placeholder="Naam"
                  @change="(value) => updateMetadata('name', value)"
                />
              </template>
            </ant-input>
          </v-col>
          <v-col>
            <ant-input label="Project Nr" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="metadata.project_number"
                  :rules="[rules.numberValidation]"
                  filled
                  placeholder="P.000001"
                  @change="(value) => updateMetadata('project_number', value)"
                />
              </template>
            </ant-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ant-input label="Bedrijf" top-margin="mt-0">
              <template #input-field>
                <v-select
                  v-if="isDaiwaLicense"
                  v-model="metadata.firm"
                  :items="['Daiwa House', 'Jan Snel', 'Medexs']"
                  filled
                  placeholder="Bedrijfsnaam"
                  @change="(value) => updateMetadata('firm', value)"
                />
                <v-text-field
                  v-else
                  v-model="metadata.firm"
                  filled
                  placeholder="Bedrijfsnaam"
                  @change="(value) => updateMetadata('firm', value)"
                />
              </template>
            </ant-input>
          </v-col>
          <v-col>
            <ant-input label="Status" top-margin="mt-0">
              <template #input-field>
                <v-select
                  v-model="metadata.status"
                  :items="['Actief', 'On Hold']"
                  filled
                  placeholder="status"
                  @change="(value) => updateMetadata('status', value)"
                />
              </template>
            </ant-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ant-input label="Project Locatie" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="metadata.location"
                  placeholder="Project locatie"
                  filled
                  @change="(value) => updateMetadata('location', value)"
                />
              </template>
            </ant-input>
          </v-col>
          <v-col>
            <ant-input label="UKG Nr" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="metadata.ukg_number"
                  placeholder="UKG nummer"
                  filled
                  @change="(value) => updateMetadata('ukg_number', value)"
                />
              </template>
            </ant-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ant-input label="Gevolg klasse" top-margin="mt-0">
              <template #input-field>
                <v-select
                  v-model="metadata.consequence_class"
                  :items="[
                    { text: 'CC1 WKB', value: 'CC1' },
                    { text: 'CC2', value: 'CC2' },
                    { text: 'CC3', value: 'CC3' },
                  ]"
                  item-text="text"
                  placeholder="Klasse CC1, CC2 of CC3"
                  item-value="value"
                  filled
                  @change="
                    (value) => updateMetadata('consequence_class', value)
                  "
                />
              </template>
            </ant-input>
          </v-col>
          <v-col>
            <ant-input label="Kwaliteits Borging Bedrijf" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="metadata.quality_assurance_firm"
                  placeholder="Bedrijfsnaam"
                  :disabled="metadata.consequence_class !== 'CC1'"
                  filled
                  @change="
                    (value) => updateMetadata('quality_assurance_firm', value)
                  "
                />
              </template>
            </ant-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ant-input label="Behoeftemelding Nr" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="metadata.requirement_notification_number"
                  item-text="text"
                  item-value="value"
                  placeholder="Nummer"
                  filled
                  @change="
                    (value) =>
                      updateMetadata('requirement_notification_number', value)
                  "
                />
              </template>
            </ant-input>
          </v-col>
          <v-col>
            <ant-input label="VCA certificatie" top-margin="mt-0">
              <template #input-field>
                <v-select
                  v-model="metadata.vca_certification"
                  filled
                  :items="['VCA**', 'VCA-P']"
                  placeholder="Certificatie VCA** of VCA-P"
                  @change="
                    (value) => updateMetadata('vca_certification', value)
                  "
                />
              </template>
            </ant-input>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
  </v-card>
</template>
